import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  /**
   * Swiper configuration
   */
  config: SwiperOptions = {
    // Speed of page swiping
    speed: 300,
    // to show pagination bullets
    pagination: { el: '.swiper-pagination' },
  };

  /**
   * Slides data
   */
  slides = [
    {
      name: 'slide-1',
      /**
       * First slide logo
       */
      logoSrc: 'assets/images/1870-white.svg',
      logo2Src: 'assets/images/elephant.svg',
      /**
       * First slide credit card image
       */
      cardImageSrc: 'assets/images/credit-card.svg',
      /**
       * First slide texts
       */
      heading1: ' Welcome to 1870',
      heading2: 'At 1870 we turn your current spending into retirement dollars',
    },
    {
      name: '',
      /**
       * Fourth images with different resolutions
       */
      slideImageSrc: 'assets/images/illustration3.svg',
      /**
       * Fourth slide texts
       */
      heading2: 'Get a $100 bonus when after opening your account and spending $1,000',
      // heading3: 'Save for retirement with every purchase you make',
    },
    {
      name: 'slide-2',
      /**
       * Second slide images with different resolutions
       */
      slideImageSrc: 'assets/images/illustration1.svg',
      /**
       * Second slide texts
       */
      heading2:
        'For every purchase you make we deposit 1.5% into a tax advantaged retirement account',
      // heading3: 'Save for retirement with every purchase you make',
    },
    {
      name: 'slide-3',
      /**
       * Third slide images with different resolutions
       */
      slideImageSrc: 'assets/images/illustration2.svg',
      /**
       * Third slide texts
       */
      // tslint:disable-next-line:quotemark
      heading2: "Buy your vacation today, we'll take care of your vacation tomorrow",
      // heading3: 'Save for retirement with every purchase you make',
    },
  ];

  constructor() { }

  ngOnInit() { }
}
